<template>
    <div>
        <!-- <img src="/img/slurp.png" alt="Vue.js logo"> -->
        <div v-if="outletName" class="banner py-3 font-weight-bold text-center">{{ outletName }}</div>
    </div>
</template>
 
<script>
export default {
    name: 'Header',
    props: {
        outletName: String,
    },
};
</script>
 
<style scoped lang="scss">
.banner {
    background: #F85757;
    color: #F85757;
    font-size: 20px;
    letter-spacing: 1px;
 
}
</style>